import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';
import { convertURL } from 'src/utils/common';

import { ISearchInterview } from '../volunteer';

import { ICriteriaSetting, ISearchUser, IUser } from './users.interface';

import { IEventAttendanceData, IVolunteerData } from '.';

export const sendEmailToParent = async (body: { parentName: string; parentEmail: string }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/send-mail-to-parent`,
    body,
  );
  return response?.data;
};

export const getUser = async (id: string) => {
  const response = await httpClient.get<IResponse<IUser>>(`${process.env.REACT_APP_API_URL}/users/${id}`);
  return response?.data;
};

export const getUsers = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchUser;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IUser>>(
    `${process.env.REACT_APP_API_URL}/users?search=${search?.search ? convertURL(search?.search) : ''}&roleName=${
      search?.roleName ?? ''
    }&committeeMember=${search?.committeeMember ?? ''}&accountStatus=${
      search?.accountStatus ?? ''
    }&verificationStatus=${search?.verificationStatus ?? ''}&accountType=${
      search?.accountType ?? ''
    }&page=${currentPage}&limit=${limit}`,
  );
  return response?.data;
};

export const getEligibleUsers = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchUser;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IUser>>(
    `${process.env.REACT_APP_API_URL}/users?search=${
      search?.search ? convertURL(search?.search) : ''
    }&verificationStatus=${search?.verificationStatus ?? ''}&accountType=${search?.accountType ?? ''}&eligibleStatus=${
      search?.eligibleStatus ?? ''
    }&fromDate=${search?.fromDate ? new Date(search?.fromDate).toLocaleDateString('en-GB') : ''}&toDate=${
      search?.toDate ? new Date(search?.toDate).toLocaleDateString('en-GB') : ''
    }&page=${currentPage}&limit=${limit}&searchEligibility=yes`,
  );
  return response?.data;
};

export const createUser = async (body: any) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users`, body);
  return response?.data;
};

export const updateRole = async (body: { role?: string; ids: string[]; action: string }) => {
  const response = await httpClient.patch<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/update-role`, body);
  return response?.data;
};

export const getMe = async () => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/me`);
  return response?.data;
};

export const changeAccountStatus = async (body: { reason?: string; ids: string[]; action: string }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/change-account-status`,
    body,
  );
  return response?.data;
};

export const getTemplateSendGrid = async () => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/get/template-sendgrid`);
  return response?.data;
};

export const sendEmail = async (body: { emails: string[]; templateId: string }) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/send-email`, body);
  return response?.data;
};

export const setCommitteeMember = async (body: { ids: string[] }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/assign-committee-member`,
    body,
  );
  return response?.data;
};

export const removeCommitteeMember = async (body: { ids: string[] }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/remove-committee-member`,
    body,
  );
  return response?.data;
};

export const approveMembership = async (body: { ids: string[] }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/approve-membership`,
    body,
  );
  return response?.data;
};

export const deleteUsers = async (body: { ids: string[] }) => {
  const response = await httpClient.delete<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/delete`, {
    data: body,
  });
  return response?.data;
};

export const exportUsersCSV = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchUser;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IUser>>(
    `${process.env.REACT_APP_API_URL}/users/export?search=${
      search?.search ? convertURL(search?.search) : ''
    }&roleName=${search?.roleName ?? ''}&committeeMember=${search?.committeeMember ?? ''}&accountStatus=${
      search?.accountStatus ?? ''
    }&verificationStatus=${search?.verificationStatus ?? ''}&accountType=${
      search?.accountType ?? ''
    }&page=${currentPage}&limit=${limit}&export=csv`,
  );

  const date = new Date();

  var downloadLink = document.createElement('a');
  var blob = new Blob(['\ufeff', response.data as any], { type: 'text/csv;charset=utf-8;' });
  var url = URL.createObjectURL(blob);
  downloadLink.target = '_blank';
  downloadLink.href = url;
  downloadLink.download = `List of Users_${date.toLocaleDateString('en-GB').split('/').join('')}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return true;
};

export const exportEligibleUsersCSV = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchUser;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IUser>>(
    `${process.env.REACT_APP_API_URL}/users/eligible-export?search=${
      search?.search ? convertURL(search?.search) : ''
    }&verificationStatus=${search?.verificationStatus ?? ''}&accountType=${search?.accountType ?? ''}&eligibleStatus=${
      search?.eligibleStatus ?? ''
    }&page=${currentPage}&limit=${limit}&searchEligibility=yes&export=csv`,
  );

  const date = new Date();

  var downloadLink = document.createElement('a');
  var blob = new Blob(['\ufeff', response.data as any], { type: 'text/csv;charset=utf-8;' });
  var url = URL.createObjectURL(blob);
  downloadLink.target = '_blank';
  downloadLink.href = url;
  downloadLink.download = `List of Eligible Users_${date.toLocaleDateString('en-GB').split('/').join('')}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return true;
};

export const getEligibleCriteriaSetting = async () => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/config/system`);
  return response?.data;
};

export const updateEligibleCriteriaSetting = async (body: ICriteriaSetting) => {
  const response = await httpClient.put<IResponse<any>>(`${process.env.REACT_APP_API_URL}/config`, body);
  return response?.data;
};

export const getHistoryChangeEligibility = async () => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/config/history`);
  return response?.data;
};
export const getRegisterVolunteer = async (status: string) => {
  const response = await httpClient.get<IResponse<IVolunteerData>>(
    `${process.env.REACT_APP_API_URL}/users/registered-volunteer?status=${status}`,
  );
  return response?.data;
};
export const getUserEventAttendance = async (id: string) => {
  const response = await httpClient.get<IResponse<IEventAttendanceData | null>>(
    `${process.env.REACT_APP_API_URL}/users/${id}/event-attendance`,
  );
  return response?.data;
};
export const getCommitteeList = async ({ search }: { search?: ISearchInterview }) => {
  const response = await httpClient.get<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/commitee-list?search=${search?.search ?? ''}`,
  );
  return response?.data;
};

export const assignInterviewer = async (body: { registrationIds: string[]; interviewIds: string[] }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/interview/assign-interview`,
    body,
  );
  return response?.data;
};

export const getMembershipStatus = async () => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/membership-status`);
  return response?.data;
};
export const getUserPaymentHistory = async ({ id, limit, page }: { id: string; limit: number; page: number }) => {
  const response = await httpClient.get<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/${id}/payment-history?limit=${limit}&page=${page}`,
  );
  return response?.data;
};

export const setUserAsEligible = async (body: { ids: string[] }) => {
  const response = await httpClient.patch<IResponse<any>>(`${process.env.REACT_APP_API_URL}/users/eligibility`, body);
  return response?.data;
};

export const setUserAsNonEligible = async (body: { ids: string[] }) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users/non-eligibility`,
    body,
  );
  return response?.data;
};
