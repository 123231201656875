import * as Yup from 'yup';

import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';

const CURRENT_YEAR = new Date().getFullYear();

export const generalInformationSchema = ({ dateOfBirth, variants }: any) => {
  const yearOfBirth = new Date(dateOfBirth).getFullYear();
  const isYearMustBeNumber = (val: any) => (Number(val) ? true : false);
  const isYearMustLessMoreCurrentYear = (val: any) => (Number(val) && Number(val) < CURRENT_YEAR ? true : false);
  const isYearMustLessMoreBirthYear = (val: any) => (Number(val) && Number(val) > yearOfBirth ? true : false);

  const THIS_FIELD_IS_REQUIRED = 'This field is required';

  const GRADUATE_YEAR_VALIDATE_ADMIN = Yup.string()
    .required(PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_EMPTY)
    .test('validate_year', PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR, isYearMustBeNumber)
    .test(
      'GRADUATE_YEAR_LESS_MORE_BIRTH_YEAR',
      PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_LESS_MORE_BIRTH_YEAR,
      isYearMustLessMoreBirthYear,
    );
  const GRADUATE_YEAR_VALIDATE_NORMAL_USER = Yup.string()
    .required(PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_EMPTY)
    .test('validate_year', PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR, isYearMustBeNumber)
    .test(
      'GRADUATE_YEAR_LESS_CURRENT_YEAR',
      PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_LESS_CURRENT_YEAR,
      isYearMustLessMoreCurrentYear,
    )
    .test(
      'GRADUATE_YEAR_LESS_MORE_BIRTH_YEAR',
      PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_LESS_MORE_BIRTH_YEAR,
      isYearMustLessMoreBirthYear,
    );

  return Yup.object({
    surname: Yup.string().max(50, 'Surname must less more 50 character').required(PROFILE_VALIDATION_MESSAGE.SURNAME),
    givenName: Yup.string()
      .max(50, 'Surname must less more 50 character')
      .required(PROFILE_VALIDATION_MESSAGE.GIVEN_NAME),
    chineseName: Yup.string().optional(),
    gender: Yup.string().required(PROFILE_VALIDATION_MESSAGE.GENDER),
    emailAddress: Yup.string()
      .max(50)
      .email(PROFILE_VALIDATION_MESSAGE.EMAIL)
      .required(PROFILE_VALIDATION_MESSAGE.EMAIL),
    areaCode: Yup.string().required(PROFILE_VALIDATION_MESSAGE.AREA_CODE),
    mobilePhone: Yup.number()
      .typeError(PROFILE_VALIDATION_MESSAGE.PHONE_NUMBER)
      .required(PROFILE_VALIDATION_MESSAGE.PHONE_NUMBER),

    isCheckPeriodStudyInNYK: Yup.boolean().optional(),
    isCheckPeriodStudyInNYP: Yup.boolean().optional(),
    isCheckPeriodStudyInNPP: Yup.boolean().optional(),
    isCheckPeriodStudyInNYGH: Yup.boolean().optional(),

    periodStudyInNPP: Yup.string().when('isCheckPeriodStudyInNPP', {
      is: true,
      then: variants === 'admin-edit' ? GRADUATE_YEAR_VALIDATE_ADMIN : GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYGH: Yup.string().when('isCheckPeriodStudyInNYGH', {
      is: true,
      then: variants === 'admin-edit' ? GRADUATE_YEAR_VALIDATE_ADMIN : GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYK: Yup.string().when('isCheckPeriodStudyInNYK', {
      is: true,
      then: variants === 'admin-edit' ? GRADUATE_YEAR_VALIDATE_ADMIN : GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYP: Yup.string().when('isCheckPeriodStudyInNYP', {
      is: true,
      then: variants === 'admin-edit' ? GRADUATE_YEAR_VALIDATE_ADMIN : GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    mailingAddress: Yup.string().required(PROFILE_VALIDATION_MESSAGE.MAILING_ADDRESS),
    postalCode: Yup.number()
      .typeError(PROFILE_VALIDATION_MESSAGE.POSTAL_CODE)
      .required(PROFILE_VALIDATION_MESSAGE.POSTAL_CODE),
    nationality: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    isChildAttend: Yup.mixed().when(
      ['isCheckPeriodStudyInNYK', 'isCheckPeriodStudyInNPP', 'isCheckPeriodStudyInNYP', 'isCheckPeriodStudyInNYGH'],
      {
        is: (
          isCheckPeriodStudyInNYK: any,
          isCheckPeriodStudyInNPP: any,
          isCheckPeriodStudyInNYP: any,
          isCheckPeriodStudyInNYGH: any,
        ) => {
          return (
            isCheckPeriodStudyInNYK || isCheckPeriodStudyInNPP || isCheckPeriodStudyInNYP || isCheckPeriodStudyInNYGH
          );
        },
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(THIS_FIELD_IS_REQUIRED),
      },
    ),
  });
};
