import { Box, IconButton, MenuItem, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';

import { IResponsive } from '../home/homepageStyled';

export const userTypes = {
  applicant: 'applicant',
  youthMember: 'youth-member',
};

export const eventCategories = {
  GTKY_PARTY: 'gtky-party',
  valueTalk: 'value-talk',
  volunteer: 'volunteer',
};

export const menus = ['User List', 'Eligible User', 'Eligibility Criteria Setting'];

export const accountTypes = [
  { key: 'applicant', value: 'Applicant' },
  { key: 'lifetime-membership', value: 'Life Member' },
  { key: 'youth-member', value: 'Youth Member' },
];

export const accountEligibilityTypes = [
  { key: 'applicant', value: 'Applicant' },
  { key: 'youth-member', value: 'Youth Member' },
];
export const verificationStatus = [
  { key: 'verified', value: 'Verified' },
  { key: 'unverified', value: 'Unverified' },
];
export const accountStatus = [
  { key: 'active', value: 'Active' },
  { key: 'blocked', value: 'Blocked' },
];
export const eligibleStatus = [
  { key: 'not-eligible', value: 'Not Eligible' },
  { key: 'eligible', value: 'Eligible' },
  { key: 'pending-member', value: 'Pending Member' },
];

export const blockingReason = [
  'User passed away',
  'User violate privacy policy and  terms of use',
  'Email bounced',
  'Youth membership Expired',
];
export const committeeMember = [
  { key: 'yes', value: 'Yes' },
  { key: 'no', value: 'No' },
];

export const adminRole = [
  { key: 'none', value: 'None' },
  { key: 'event-ic', value: 'Event IC' },
  { key: 'membership-admin', value: 'Membership Admin' },
  { key: 'secretariat-admin', value: 'Secretariat Admin' },
  { key: 'it-admin', value: 'IT Admin' },
  { key: 'accountant', value: 'Accountant' },
];

export const assignRoleLists = [
  { key: 'event-ic', value: 'Event IC' },
  { key: 'membership-admin', value: 'Membership Admin' },
  { key: 'secretariat-admin', value: 'Secretariat Admin' },
  { key: 'it-admin', value: 'IT Admin' },
  { key: 'accountant', value: 'Accountant' },
];

export const itAdminActions = ['Assign Role', 'Remove Role'];

export const memberShipAdminActions = ['Send Email', 'Block Account', 'Unblock Account', 'Delete User'];

export const secretariatAdminActions = [
  'Send Email',
  'Set as Committee Member',
  'Remove Committee Member',
  'Block Account',
  'Unblock Account',
  'Delete User',
];

export const eligibleUserActions = [
  'Send Email',
  'Approve Membership',
  'Delete User',
  'Set user as Eligible',
  'Set user as Non-Eligible',
];

export const eventCategory = [
  { key: 'founder-s-day-luncheon-dinner', value: "Founder's Day Luncheon Dinner" },
  { key: 'gtky-party', value: 'GTKY Party' },
  { key: 'others', value: 'Others' },
  { key: 'p1-registration-talk', value: 'P1 Registration Talk' },
  { key: 'tuan-bai', value: 'Tuan Bai' },
  { key: 'value-talk', value: 'Value Talk' },
];

export const defaultSearchUser = {
  search: '',
  roleName: '',
  committeeMember: '',
  accountStatus: '',
  verificationStatus: '',
  accountType: '',
};

export const defaultSearchEligibleUser = {
  search: '',
  eligibleStatus: '',
  verificationStatus: '',
  accountType: '',
  fromDate: null,
  toDate: null,
};

export const Container = styled(Box)(({ theme }) => ({
  boxShadow: '1px 1px 4px #9E9E9E',
  borderRadius: 4,
}));

export const Header = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
}));

export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
  marginLeft: 16,
}));

export const IconStyled = styled.img(() => ({
  marginRight: 12,
}));

export const IconVerifyStyled = styled.img(() => ({
  marginLeft: 3,
  width: 10,
  height: 10,
}));

export const ImageIconStyled = styled.img(() => ({}));

export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 2,
}));

export const MenuItemStyled = styled(MenuItem)(() => ({
  height: 40,
  minWidth: 217,
}));

export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(() => ({
  marginLeft: 12,
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4,
}));

export const Actions = styled(Box)`
  display: flex;
  gap: 5px;
`;

export const ButtonStatus = styled(Typography)<{
  variants?: 'blocked' | 'active' | 'not-eligible' | 'eligible' | 'pending-member' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'active'
      ? theme.palette.blue?.[1000]
      : variants === 'blocked'
      ? theme.palette.gray?.[75]
      : variants === 'not-eligible'
      ? theme.palette.red?.[150]
      : variants === 'eligible'
      ? theme.palette.green?.[900]
      : variants === 'pending-member'
      ? theme.palette.yellow?.[150]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
  `}
`;

export const BoxUserInfoStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 16px',
  alignItems: 'center',
}));

export const UserInfoHeaderTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header6,
  color: 'white',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header6,
  color: 'white',
}));

export const ClearIconStyled = styled(ClearIcon)(({ theme }) => ({
  color: 'white',
}));

export const LabelStyled = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSBold,
}));
export const TypographyUserInfo = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(51, 51, 51, 1)',
  wordBreak: 'break-all',
}));

export const CustomBox = styled(Box)(() => ({
  margin: '40px',
  padding: '48px 24px',
  border: '1px solid rgba(197, 190, 139, 1)',
  minHeight: 400,
  borderRadius: 4,
}));

export const CaptionStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(197, 190, 139, 1)',
}));

export const ViewHistoryButton = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: '#C5BE8B',
  marginLeft: 30,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
export const UpdateText = styled(Typography)(() => ({
  color: 'rgba(51, 51, 51, 1)',
  fontSize: 14,
  fontWeight: 400,
}));
export const TypographyTitleStyled = styled(Typography)(() => ({
  color: 'rgba(51, 51, 51, 1)',
  fontSize: 16,
  fontWeight: 700,
}));

export const UserManagementContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;
